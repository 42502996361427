import {FC, lazy} from 'react'
import {Navigate} from 'react-router-dom'
// constants
import {PURCHASE_ROUTE} from 'constants/routes'
// hooks
import useUser from 'hooks/useUser'
// components
const PublicLayout = lazy(() => import('components/template/layout/PublicLayout'))

interface IProtectedRouteProps {}

const ProtectedRoute: FC<IProtectedRouteProps> = () => {
  const {isLogin} = useUser()

  return !isLogin ? <PublicLayout /> : <Navigate replace to={PURCHASE_ROUTE} />
}

export default ProtectedRoute
