export const INTRO_ROUTE = '/intro'
export const REGISTER_ROUTE = '/register'
export const REGISTER_CODE_ROUTE = REGISTER_ROUTE + '/confirm'
export const REGISTER_PASSWORD_ROUTE = REGISTER_ROUTE + '/make-password'
export const LOGIN_ROUTE = '/login'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const FORGOT_PASSWORD_SUCCESS_ROUTE = FORGOT_PASSWORD_ROUTE + '/success'
export const FORGOT_PASSWORD_CONFIRM_ROUTE = FORGOT_PASSWORD_ROUTE + '/confirm'

export const NOT_FOUND_ROUTE = '*'
