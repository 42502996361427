import {createContext, useContext} from 'react'
import {AuthenticateProps} from 'services/auth'

export interface IPublicRouteContext {
  authData: AuthenticateProps & {time: Date}
  setAuthData: React.Dispatch<React.SetStateAction<AuthenticateProps & {time: Date}>>
}

export default createContext<IPublicRouteContext>({
  authData: {
    time: new Date(),
    mobile_number: '',
    has_account: false,
    has_password: false,
    sms_ttl: 0,
    code: '',
  },
  setAuthData: () => {},
})
