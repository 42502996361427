import {IProvince, Province} from './Province'
import {ICity, City} from './City'
import {District, IDistrict} from './District'
import {IRegionDeliveryPlan} from './RegionDeliveryPlan'
import {RegionDeliveryPlan} from './RegionDeliveryPlan'

export interface IAddress {
  id?: string
  createdAt?: string
  updatedAt?: string
  lat?: string
  long?: string
  description?: string
  location?: string
  postcode?: string
  streetOne?: string
  streetTwo?: string
  houseNumber?: string
  unitNumber?: string
  address?: string
  districtId?: string
  provinceId?: string
  cityId?: string
  province?: IProvince
  city?: ICity
  customerId?: string
  adminId?: string
  vendorId?: string
  deliveryRegionId?: string
  name?: string
  receiverName?: string
  district?: IDistrict
  isActive?: boolean
  deliveryPlans?: IRegionDeliveryPlan[]
  fullAddress?: string
  hasDelivery?: boolean
}

export class Address {
  public props: IAddress = {}

  constructor(data?: IAddress) {
    if (data) {
      this.props = data
    }
  }

  getAddress(): string {
    return this.props.address || ''
  }

  getCityId(): string {
    return this.props.cityId || ''
  }

  getName(): string {
    return this.props.name || ''
  }

  getReceiverName(): string {
    return this.props.receiverName || ''
  }

  getIsActive(): boolean {
    return this.props.isActive || false
  }

  getProvinceId(): string {
    return this.props.provinceId || ''
  }

  getProvince(): Province {
    return new Province(this.props.province || {})
  }

  getCity(): City {
    return new City(this.props.city || {})
  }

  getLocation(): string {
    return this.props.location || ''
  }

  getDistrict(): District {
    return new District(this.props.district || undefined)
  }

  getPostCode(): string {
    return this.props.postcode || ''
  }

  getStreetOne(): string {
    return this.props.streetOne || ''
  }

  getStreetTwo(): string {
    return this.props.streetTwo || ''
  }

  getHouseNumber(): string {
    return this.props.houseNumber || ''
  }

  getUnitNumber(): string {
    return this.props.unitNumber || ''
  }

  getCustomeId(): string {
    return this.props.customerId || ''
  }

  getDistrictId(): string {
    return this.props.districtId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getVendorId(): string {
    return this.props.vendorId || ''
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getRegionDeliveryPlans(): RegionDeliveryPlan[] {
    return (
      this.props.deliveryPlans?.map((deliveryPlan) => new RegionDeliveryPlan(deliveryPlan)) || []
    )
  }

  getLat(): string {
    return this.props.lat || ''
  }

  getLong(): string {
    return this.props.long || ''
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getFullAddress(): string {
    return this.props.fullAddress || ''
  }

  getHasDelivery(): boolean {
    return this.props.hasDelivery || false
  }

  getDisplayFullAddress(): string {
    return [
      this.getProvince().getLabel(),
      this.getCity().getLabel(),
      this.getDistrict().getLabel(),
      this.getStreetOne(),
      this.getStreetTwo(),
      this.getHouseNumber() ? 'پلاک' + this.getHouseNumber() : '',
      this.getUnitNumber() ? 'واحد' + this.getUnitNumber() : '',
    ]
      .filter((text) => text.length > 0)
      .join('، ')
  }

  getDisplayBasicAddress(): string {
    return (
      [this.getProvince().getLabel(), this.getCity().getLabel(), this.getDistrict().getLabel()]
        .filter((text) => text.length > 0)
        .join('، ') + '، '
    )
  }

  getDisplayAddress(): string {
    return [
      this.getCity().getLabel(),
      this.getDistrict().getLabel(),
      // this.getStreetOne() ? this.getStreetOne() : '',
      // this.getStreetTwo() ? this.getStreetTwo() : '',
      // this.getHouseNumber() ? 'پلاک' + this.getHouseNumber() : '',
      // this.getUnitNumber() ? 'واحد' + this.getUnitNumber() : '',
    ]
      .filter((text) => text.length > 0)
      .join(', ')
  }
}
