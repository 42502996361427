import {Admin, IAdmin} from './Admin'
import {IPackagingTarget, PackagingTarget} from './PackagingTarget'
import {Photo} from './Photo'
import {IPhotoUse, PhotoUse} from './PhotoUse'

export enum BoxTypeFaEnum {
  all = 'همه',
  standard = 'سبدهای آماده',
  custom = 'سبدهای سفارشی',
}

export enum BoxTypeEnum {
  all = 'all',
  standard = 'standard',
  custom = 'custom',
}

export enum FilterBasketEnum {
  all = 0,
  custom = 1,
  standard = 2,
}
export interface IBox {
  id?: string
  name?: string
  description?: string
  type?: BoxTypeEnum
  totalWeight?: number
  price?: number
  startDate?: string
  endDate?: string
  adminId?: string
  admin?: IAdmin
  photoUses?: IPhotoUse[]
  subscribersCount?: number
  packagingTargets?: IPackagingTarget[]
}

export class Box {
  public props: IBox = {}

  constructor(data?: IBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getName(): string {
    return this.props.name || ''
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getTotalWeight(): number {
    return this.props.totalWeight || 0
  }

  getSubscribersCount(): number {
    return this.props.subscribersCount || 0
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrder() - b.getOrder())
  }

  getMainImage(): Photo {
    return this.getPhotoUses().length > 0 ? this.getPhotoUses()[0].getPhoto() : new Photo()
  }

  getPrice(): number {
    return this.props.price || 0
  }

  getStartDate(): Date | undefined {
    return this.props.startDate ? new Date(this.props.startDate) : undefined
  }

  getEndDate(): Date | undefined {
    return this.props.endDate ? new Date(this.props.endDate) : undefined
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getType(): BoxTypeEnum {
    return this.props.type || BoxTypeEnum.standard
  }

  getPackagingTargets(): PackagingTarget[] {
    return (this.props.packagingTargets || []).map(
      (packagingTarget) => new PackagingTarget(packagingTarget)
    )
  }

  // utils
  getDisplayName(): string {
    const name = this.getName().replace('جعبه', '').replace('سفارشی', '')
    return `سرسبد ${name}`
  }

  getHasName(): boolean {
    const name = this.getName().replace('جعبه', '').replace('سفارشی', '')
    return !!name
  }

  getLastActivePackagingTarget(): PackagingTarget {
    const packagingTargets = this.getPackagingTargets()

    return packagingTargets[packagingTargets.length - 1]
  }
}

export const displayBoxTypeEnum = (boxTypeEnum?: BoxTypeEnum): string => {
  switch (boxTypeEnum) {
    case BoxTypeEnum.custom:
      return 'سفارشی'
    case BoxTypeEnum.standard:
      return 'آماده'
    default:
      return ''
  }
}
