import {IPhoto, Photo} from './Photo'
import {IUser, User} from './User'

export enum PhotoUseStatusEnum {
  rejected = 'rejected',
  accepted = 'accepted',
  pending = 'pending',
}

export interface IPhotoUse {
  id?: string
  isAvatar?: true
  order?: number
  orderNumber?: number
  photo?: IPhoto
  photoHistory?: IPhoto
  user?: IUser
  status?: PhotoUseStatusEnum
}

export class PhotoUse {
  public props: IPhotoUse = {}

  constructor(data?: IPhotoUse) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getIsAvatar(): boolean {
    return !!this.props.isAvatar
  }

  getOrder(): number {
    return this.props.order || 0
  }

  getOrderNumber(): number {
    return this.props.orderNumber || 0
  }

  getPhoto(): Photo {
    return new Photo(this.props.photo || {})
  }

  getPhotoHistory(): Photo {
    return new Photo(this.props.photoHistory || {})
  }

  getUser(): User {
    return new User(this.props.user || {})
  }

  getStatus(): PhotoUseStatusEnum {
    return this.props.status || PhotoUseStatusEnum.rejected
  }
}

export const displayPhotoUseStatusEnum = (photoUseStatusEnum: PhotoUseStatusEnum): string => {
  switch (photoUseStatusEnum) {
    case PhotoUseStatusEnum.rejected:
      return 'رد شده'
    case PhotoUseStatusEnum.accepted:
      return 'تایید شده'
    case PhotoUseStatusEnum.pending:
      return 'در انتظار تایید'
    default:
      return ''
  }
}
