export interface IProductType {
  id?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string | null
  name?: string
  faName?: string
  isActive?: boolean
}

export class ProductType {
  public props: IProductType = {}

  constructor(data?: IProductType) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getName(): string {
    return this.props.name || ''
  }

  getFaName(): string {
    return this.props.faName || ''
  }

  getIsActive(): boolean | undefined {
    return this.props.isActive || undefined
  }
}
