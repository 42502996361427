import {TicketResponseCreatedByEnum} from 'enums/ticket'
import {Admin, AdminRolesEnum, IAdmin, displayAdminRolesEnum} from './Admin'
import {Customer, ICustomer} from './Customer'
import {ITicket, Ticket} from './Ticket'
import {IPhotoUse, PhotoUse} from './PhotoUse'
import {Photo} from './Photo'

export interface ITicketResponse {
  id?: string
  createdAt?: string
  updatedAt?: string
  body?: string
  date?: string
  createdBy?: TicketResponseCreatedByEnum
  assigned?: AdminRolesEnum[]
  isReference?: boolean
  adminId?: string
  ticketId?: string
  customerId?: string
  admin?: IAdmin
  customer?: ICustomer
  ticket?: ITicket
  photoUses?: IPhotoUse[]
}

export class TicketResponse {
  public props: ITicketResponse = {}

  constructor(data?: ITicketResponse) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getBody(): string {
    return this.props.body || ''
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getCreatedBy(): TicketResponseCreatedByEnum {
    return this.props.createdBy as TicketResponseCreatedByEnum
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getIsReference(): boolean {
    return this.props.isReference || false
  }

  getAssigned(): AdminRolesEnum[] {
    return this.props.assigned || []
  }

  getDisplayAssigned(): string {
    return this.getAssigned()
      .map((role) => displayAdminRolesEnum(role))
      .join(', ')
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getTicket(): Ticket {
    return new Ticket(this.props.ticket || {})
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrderNumber() - b.getOrderNumber())
  }

  getMainImage(): Photo {
    if (this.props.photoUses && this.props.photoUses.length > 0) {
      return new PhotoUse(this.props.photoUses[0]).getPhoto()
    }
    return new Photo()
  }
}
