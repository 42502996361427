import {Box, IBox} from './Box'
import {IPackagingTarget, PackagingTarget} from './PackagingTarget'
import {ISubscription, Subscription} from './Subscription'
import {ISubscriptionBoxProduct, SubscriptionBoxProduct} from './SubscriptionBoxProduct'

export interface ISubscriptionBox {
  id?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  quantity?: number
  totalPrice?: number
  boxId?: string
  packagingTargetId?: string
  subscription?: ISubscription
  packagingTarget?: IPackagingTarget
  box?: IBox
  subscriptionBoxProducts?: ISubscriptionBoxProduct[]
}

export class SubscriptionBox {
  public props: ISubscriptionBox = {}

  constructor(data?: ISubscriptionBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDeletedAt(): Date | undefined {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : undefined
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }

  getBoxId(): string | undefined {
    return this.props.boxId
  }

  getSubscription(): Subscription {
    return new Subscription(this.props.subscription)
  }

  getPackagingTargetId(): string | undefined {
    return this.props.packagingTargetId
  }

  getPackagingTarget(): PackagingTarget {
    return new PackagingTarget(this.props.packagingTarget)
  }

  getBox(): Box {
    return new Box(this.props.box)
  }

  getSubscriptionBoxProducts(): SubscriptionBoxProduct[] {
    return (this.props.subscriptionBoxProducts || []).map(
      (subscriptionBoxProduct: ISubscriptionBoxProduct) =>
        new SubscriptionBoxProduct(subscriptionBoxProduct)
    )
  }
}
