import {toAbsoluteUrl} from 'utils/template/helpers'
import {Photo} from './Photo'
import {IPhotoUse, PhotoUse} from './PhotoUse'
import {IProductInstance, ProductInstance} from './ProductInstance'
import {IProductType, ProductType} from './ProductType'

export interface IProduct {
  price?: number
  id?: string
  createdAt?: string
  updateAt?: string
  name?: string
  description?: string
  percentagePrice?: number
  status?: 'activated' | 'deactivated'
  icon?: string | null
  averageRefuse?: number
  orderLineId?: string
  productTypeId?: string
  productUnitId?: string
  star?: number
  adminId?: string
  lastPrice?: number
  photoUses?: IPhotoUse[]
  productType?: IProductType
  productInstances?: IProductInstance[]
  weightInKilogram?: number
  customPrice?: number
  useCustomPrice?: boolean
}

export class Product {
  public props: IProduct = {}

  constructor(data?: IProduct) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getProductInstanceId(): string {
    return this.getProductInstances()[0].getId()
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updateAt ? new Date(this.props.updateAt) : undefined
  }

  getName(): string {
    return this.props.name || ''
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAverageRefuse(): number {
    return this.props.averageRefuse || 0
  }

  getCustomPrice(): number {
    return this.props.customPrice || 0
  }

  getUseCustomPrice(): boolean {
    return this.props.useCustomPrice || false
  }

  getPrice(): number {
    return this.getUseCustomPrice() ? this.getCustomPrice() : this.props.price || 0
  }

  getPercentagePrice(): number {
    return this.props.percentagePrice || 0
  }

  getLastPrice(): number {
    return this.props.lastPrice || 0
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || []).map((photoUse: IPhotoUse) => new PhotoUse(photoUse))
  }

  getMainImage(): Photo {
    if (this.props.photoUses && this.props.photoUses.length > 0) {
      return new PhotoUse(this.props.photoUses[0]).getPhoto()
    }
    return new Photo()
  }

  getProductInstances(): ProductInstance[] {
    return (this.props.productInstances || []).map(
      (productInstance) => new ProductInstance(productInstance)
    )
  }

  getSmallestProductInstance(): ProductInstance {
    return this.getProductInstances()[0]
  }

  getMinQuantity(): number {
    return this.getSmallestProductInstance().getQuantity() || 0
  }

  getProductType(): ProductType {
    return new ProductType(this.props.productType)
  }

  getIcon(): string {
    return this.props.icon || toAbsoluteUrl('/media/sarsabad/default-avatar.png')
  }

  getStar(): number {
    return this.props.star || 0
  }

  getStatus(): 'activated' | 'deactivated' {
    return this.props.status || 'activated'
  }

  // custom
  getDisplayType(): string {
    return this.getProductType().getFaName()
  }

  getProductUnitDisplayType(): string {
    return this.getSmallestProductInstance()?.getProductUnit()?.getFaName() || ''
  }

  getWeightInKilogram(): number {
    return this.props.weightInKilogram || 0
  }
  getInstanceRatio(): number {
    return this.getUseCustomPrice() ? 1 : this.getPercentagePrice() / 100 + 1
  }
}
