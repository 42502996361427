import {SubscriptionStatusEnum} from 'enums/subscription'
import {Customer, ICustomer} from './Customer'
import {Box, IBox} from './Box'
import {IPackagingTarget, PackagingTarget} from './PackagingTarget'
import {BoxPrice, IBoxPrice} from './BoxPrice'
import {Address, IAddress} from './Address'
import {IRegion, Region} from './Region'
import {DeliveryPlan, IDeliveryPlan} from './DeliveryPlan'
import {Admin, IAdmin} from './Admin'
import {ITransaction, Transaction} from './Transaction'
import {Delivery, IDelivery} from './Delivery'
import {DeliveryBox} from './DeliveryBox'
import {ISubscriptionBox, SubscriptionBox} from './SubscriptionBox'

export interface ISubscription {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  totalPrice?: number
  subscriptionNumber?: string
  subscriptionBoxes?: ISubscriptionBox[]
  weeklyCount?: number
  status?: SubscriptionStatusEnum
  description?: string | null
  regionId?: string
  boxPriceId?: string
  addressId?: string
  packagingTargetId?: string
  deliveryPlanId?: string
  customerId?: string
  adminId?: string
  box?: IBox
  boxId?: string
  customer?: ICustomer
  packagingTarget?: IPackagingTarget
  boxPrice?: IBoxPrice
  address?: IAddress
  region?: IRegion
  deliveryPlan?: IDeliveryPlan
  admin?: IAdmin
  transactions?: ITransaction[]
  deliveries?: IDelivery[]
}

export class Subscription {
  public props: ISubscription = {}

  constructor(data?: ISubscription) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }

  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }

  getSubscriptionNumber(): string | undefined {
    return this.props.subscriptionNumber
  }

  getWeeklyCount(): number {
    return this.props.weeklyCount || 0
  }

  getStatus(): SubscriptionStatusEnum {
    return this.props.status as SubscriptionStatusEnum
  }

  getDescription(): string | null {
    return this.props.description || ''
  }

  getRegionId(): string {
    return this.props.regionId || ''
  }

  getBoxPriceId(): string {
    return this.props.boxId || ''
  }

  getAddressId(): string {
    return this.props.addressId || ''
  }

  getPackagingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getDeliveryPlanId(): string {
    return this.props.deliveryPlanId || ''
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getBox(): Box {
    return new Box(this.props.box)
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getSubscriptionBoxes(): SubscriptionBox[] {
    return (this.props.subscriptionBoxes || []).map((box) => new SubscriptionBox(box))
  }

  getPackagingTarget(): PackagingTarget {
    return new PackagingTarget(this.props.packagingTarget || {})
  }

  getBoxPrice(): BoxPrice {
    return new BoxPrice(this.props.boxPrice || {})
  }

  getAddress(): Address {
    return new Address(this.props.address || {})
  }

  getRegion(): Region {
    return new Region(this.props.region || {})
  }

  getDeliveryPlan(): DeliveryPlan {
    return new DeliveryPlan(this.props.deliveryPlan || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getTransactions(): Transaction[] {
    return (this.props.transactions || []).map(
      (transaction: ITransaction) => new Transaction(transaction)
    )
  }

  getDeliveries(): Delivery[] {
    return (this.props.deliveries || []).map((delivery: IDelivery) => new Delivery(delivery))
  }

  /* -------------------------------------------------------------------------- */
  /*                             Customs                                       */
  /* -------------------------------------------------------------------------- */
  getStatusDisplay(): string {
    switch (this.props.status) {
      case SubscriptionStatusEnum.pending:
        return 'در انتظار پرداخت'
      case SubscriptionStatusEnum.accepted:
        return 'پرداخت‌ شده'
      case SubscriptionStatusEnum.expired:
        return 'منقضی شده'
      case SubscriptionStatusEnum.rejected:
        return 'رد شده'
      case SubscriptionStatusEnum.delivered:
        return 'تحویل داده شده'
      case SubscriptionStatusEnum.replace:
        return 'جایگزین شده'
      case SubscriptionStatusEnum.refund:
        return 'لغو شده'
      case SubscriptionStatusEnum.cancel:
        return 'لغو شده'
      case SubscriptionStatusEnum.failed:
        return 'تراکنش ناموفق'
      default:
        return ''
    }
  }
  getStatusColor(): string {
    switch (this.props.status) {
      case SubscriptionStatusEnum.pending:
        return '#FFB547'
      case SubscriptionStatusEnum.accepted:
        return '#1BB55C'
      case SubscriptionStatusEnum.expired:
        return '#E74C3C'
      case SubscriptionStatusEnum.rejected:
        return '#E74C3C'
      case SubscriptionStatusEnum.delivered:
        return '#1BB55C'
      case SubscriptionStatusEnum.replace:
        return '#FFB547'
      case SubscriptionStatusEnum.cancel:
        return '#E74C3C'
      default:
        return '#FFB547'
    }
  }

  getDisplayMonthlyCount(): string {
    switch (this.props.weeklyCount) {
      case 1:
        return 'فقط یکبار'
      case 2:
        return 'دو هفته یکبار'
      case 3:
        return 'سه بار در ماه'
      case 4:
        return 'چهار بار در ماه'
      case 5:
        return 'پنج بار در ماه'
      case 6:
        return 'شش بار در ماه'
      case 7:
        return 'هفت بار در ماه'
      case 8:
        return 'هشت بار در ماه'
      case 9:
        return 'نه بار در ماه'
      case 10:
        return 'ده بار در ماه'
      default:
        return ''
    }
  }
}
