import {keyframes} from '@emotion/react'
import {makeStyles} from 'tss-react/mui'
const spin = keyframes`
    0% {
      transform: translateY(0px)
    }
    40% {
      transform: translateY(-8px);
    }
    80%,
    100% {
      transform: translateY(0px);
    }
`
export const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(0.5),
      // flexDirection: "column",
      width: '100%',
      minHeight: '100px',
      '& p': {
        marginBottom: theme.spacing(0),
        fontSize: '16px',
      },
    },
    circular: {
      color: '#005927!important',
    },
    linear: {
      width: '100px',
      height: '3px !important',
    },
    loadingLetter: {
      fontWeight: 700,
      animationName: spin,
      animationDuration: '1s',
      animationIterationCount: 'infinite',
      paddingBottom: theme.spacing(0.5),
      '&:nth-of-type(2)': {
        animationDelay: '.1s',
      },
      '&:nth-of-type(3)': {
        animationDelay: '.2s',
      },
      '&:nth-of-type(4)': {
        animationDelay: '.3s',
      },
    },

    '@keyframes bounce': {
      '0%': {
        transform: 'translateY(0px)',
      },
      '40%': {
        transform: 'translateY(-40px)',
      },
      '100%': {
        transform: 'translateY(0px)',
      },
    },
  }
})
