import {IProductUnit, ProductUnit} from './ProductUnit'

export interface IProductInstance {
  id?: string
  price?: number
  useCustomPrice?: boolean
  customPrice?: number
  createdAt?: string
  updatedAt?: string
  quantity?: number
  isActive?: boolean
  productId?: string
  productUnitId?: string
  productUnit?: IProductUnit
}

export class ProductInstance {
  public props: IProductInstance = {}

  constructor(data?: IProductInstance) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCustomPrice(): number {
    return this.props.customPrice || 0
  }

  getUseCustomPrice(): boolean {
    return this.props.useCustomPrice || false
  }

  getPrice(): number {
    return this.getUseCustomPrice() ? this.getCustomPrice() : this.props.price || 0
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getIsActive(): boolean | undefined {
    return this.props.isActive || undefined
  }

  getProductUnitId(): string {
    return this.props.productUnitId || ''
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getProductUnit(): ProductUnit {
    return new ProductUnit(this.props.productUnit || {})
  }
}
