import {FC, useState} from 'react'

// local
import Context from './context'
import {AuthenticateProps} from 'services/auth'
import NavigationOutlet from 'components/navigationOutlet'

const ForgotPasswordLayout: FC = () => {
  const [authData, setAuthData] = useState<AuthenticateProps & {time: Date}>({
    mobile_number: '',
    has_account: false,
    has_password: false,
    sms_ttl: 0,
    code: '',
    time: new Date(),
  })

  return (
    <Context.Provider
      value={{
        authData,
        setAuthData,
      }}
    >
      <NavigationOutlet />
    </Context.Provider>
  )
}

export default ForgotPasswordLayout
