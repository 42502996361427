export enum DeliveryPlanDayOfWeekEnum {
  saturday = 'saturday',
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
}

export enum DeliveryPlanShiftEnum {
  morning = 'morning',
  after_noon = 'after_noon',
}

export enum DeliveryPlanStatusEnum {
  activate = 'activate',
  deactivate = 'deactivate',
}

export interface IDeliveryPlan {
  id?: string
  createdAt?: string
  updatedAt?: string
  status?: DeliveryPlanStatusEnum
  priority?: number
  dayOfWeek?: DeliveryPlanDayOfWeekEnum
  shift?: DeliveryPlanShiftEnum
}

export class DeliveryPlan {
  public props: IDeliveryPlan = {}

  constructor(data?: IDeliveryPlan) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getStatus(): DeliveryPlanStatusEnum {
    return this.props.status || DeliveryPlanStatusEnum.deactivate
  }

  getPriority(): number {
    return this.props.priority || -1
  }

  getDayOfWeek(): DeliveryPlanDayOfWeekEnum {
    return this.props.dayOfWeek || DeliveryPlanDayOfWeekEnum.saturday
  }

  getShift(): DeliveryPlanShiftEnum {
    return this.props.shift || DeliveryPlanShiftEnum.morning
  }

  // custom
  getDisplayStatus(): string {
    return displayDeliveryPlanStatusEnum(this.getStatus())
  }

  getDisplayDayOfWeek(): string {
    return displayDeliveryPlanDayOfWeekEnum(this.getDayOfWeek())
  }

  getDisplayShift(): string {
    return displayDeliveryPlanShiftEnum(this.getShift())
  }

  getDisplayRangeShift(): string {
    return this.getShift() === DeliveryPlanShiftEnum.morning ? 'ساعت ۱۰ تا ۱۴' : 'ساعت ۱۴ تا ۱۸'
  }
}

export const displayDeliveryPlanStatusEnum = (
  deliveryPlanStatusEnum?: DeliveryPlanStatusEnum
): string => {
  switch (deliveryPlanStatusEnum) {
    case DeliveryPlanStatusEnum.activate:
      return 'فعال'
    case DeliveryPlanStatusEnum.deactivate:
      return 'غیر فعال'
    default:
      return ''
  }
}

export const displayDeliveryPlanDayOfWeekEnum = (
  deliveryPlanDayOfWeekEnum?: DeliveryPlanDayOfWeekEnum
): string => {
  switch (deliveryPlanDayOfWeekEnum) {
    case DeliveryPlanDayOfWeekEnum.saturday:
      return 'شنبه'
    case DeliveryPlanDayOfWeekEnum.sunday:
      return 'یک‌شنبه'
    case DeliveryPlanDayOfWeekEnum.monday:
      return 'دوشنبه'
    case DeliveryPlanDayOfWeekEnum.tuesday:
      return 'سه‌شنبه'
    case DeliveryPlanDayOfWeekEnum.wednesday:
      return 'چهارشنبه'
    case DeliveryPlanDayOfWeekEnum.thursday:
      return 'پنج‌شنبه'
    case DeliveryPlanDayOfWeekEnum.friday:
      return 'جمعه'
    default:
      return ''
  }
}

export const getDeliveryPlanDayOfWeekNumber = (dayOfWeek?: string): number => {
  switch (dayOfWeek) {
    case DeliveryPlanDayOfWeekEnum.saturday:
      return 0
    case DeliveryPlanDayOfWeekEnum.sunday:
      return 1
    case DeliveryPlanDayOfWeekEnum.monday:
      return 2
    case DeliveryPlanDayOfWeekEnum.tuesday:
      return 3
    case DeliveryPlanDayOfWeekEnum.wednesday:
      return 4
    case DeliveryPlanDayOfWeekEnum.thursday:
      return 5
    case DeliveryPlanDayOfWeekEnum.friday:
      return 6
    default:
      return NaN
  }
}

export const displayDeliveryPlanShiftEnum = (
  deliveryPlanShiftEnum?: DeliveryPlanShiftEnum
): string => {
  switch (deliveryPlanShiftEnum) {
    case DeliveryPlanShiftEnum.morning:
      return 'صبح'
    case DeliveryPlanShiftEnum.after_noon:
      return 'عصر'
    default:
      return ''
  }
}
