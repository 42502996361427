import {FC, lazy} from 'react'
import {Navigate} from 'react-router-dom'
// hooks
import useUser from 'hooks/useUser'
// constants
import {INTRO_ROUTE} from 'constants/routes'
// components
const MasterLayout = lazy(() =>
  import('components/template/layout/MasterLayout').then((module) => ({
    default: module.MasterLayout,
  }))
)

interface IProtectedRouteProps {}

const ProtectedRoute: FC<IProtectedRouteProps> = () => {
  const {isLogin} = useUser()

  return isLogin ? <MasterLayout /> : <Navigate replace to={INTRO_ROUTE} />
}

export default ProtectedRoute
