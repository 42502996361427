export enum FAQStatusEnum {
  activated = 'activated',
}

export interface IFAQCategory {
  id?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string | null
  name?: string
  status?: FAQStatusEnum
}

export class FAQCategory {
  public props: IFAQCategory = {}

  constructor(data?: IFAQCategory) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDeletedAt(): Date | null {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : null
  }

  getName(): string {
    return this.props.name || ''
  }

  getStatus(): FAQStatusEnum {
    return this.props.status || FAQStatusEnum.activated
  }
}

export const displayFAQStatusEnum = (FAQStatus?: FAQStatusEnum): string => {
  switch (FAQStatus) {
    case FAQStatusEnum.activated:
      return 'فعال'
    default:
      return ''
  }
}
