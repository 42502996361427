import {CustomerCartItem, ICustomerCartItem} from './CustomerCartItem'
import {IPackagingTarget, PackagingTarget} from './PackagingTarget'

export interface ICart {
  id?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  customerId?: string
  customerCartItems?: ICustomerCartItem[]
}

export class Cart {
  public props: ICart = {}

  constructor(data?: ICart) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDeletedAt(): string | undefined {
    return this.props.deletedAt
  }

  getCustomerId(): string | undefined {
    return this.props.customerId
  }

  getCustomerCartItems(): CustomerCartItem[] {
    return this.props.customerCartItems?.map((item) => new CustomerCartItem(item)) || []
  }
}
