import {Admin, IAdmin} from './Admin'
import {IProduct, Product} from './Product'
import {IProductInstance, ProductInstance} from './ProductInstance'

export interface IPackagingContentTarget {
  id?: string
  createdAt?: string
  endDate?: string
  adminId?: string
  packagingTargetId?: string
  productId?: string
  updatedAt?: string
  weight?: number
  quantity?: number
  product?: IProduct
  productInstance?: IProductInstance
  productInstanceId?: string
  admin?: IAdmin
  price?: number
}

export class PackagingContentTarget {
  public props: IPackagingContentTarget = {}

  constructor(data?: IPackagingContentTarget) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getPackagingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getWeight(): number {
    return this.props.weight || 0
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getEndDate(): Date | undefined {
    return this.props.endDate ? new Date(this.props.endDate) : undefined
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }
  getQuantity(): number {
    return this.props.quantity || 0
  }

  getProductInstance(): ProductInstance {
    return new ProductInstance(this.props.productInstance)
  }
  getProductInstanceId(): string | undefined {
    return this.props.productInstanceId
  }
  getPrice(): number {
    return this.props.price || 0
  }
}
