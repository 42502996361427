import {
  ICustomer,
  IAddress,
  Address,
  Customer,
  IAdmin,
  IPayment,
  IWalletTransaction,
  Payment,
  Admin,
  WalletTransaction,
  ICourier,
  ISubscription,
  Courier,
  Subscription,
  IDeliveryPlan,
  DeliveryPlan,
} from 'models'
import {DeliveryBox, IDeliveryBox} from './DeliveryBox'
import moment from 'jalali-moment'

export enum DeliveryStatusEnum {
  processing = 'processing',
  success = 'success',
  cancel = 'cancel',
  customer_absence = 'customer_absence',
}

export enum DeliveryStateEnum {
  pending = 'pending',
  rejected = 'rejected',
  // The label is ready to be printed and attached to the parcel.
  ready = 'ready',
  // The parcel has arrived at the destination and is out for delivery.
  out_for_delivery = 'out_for_delivery',
  // Your parcel is in transit to the destination location.
  in_transit = 'in_transit',
  // The courier was unable to deliver the parcel or contact the consignee to arrange delivery. The receiver should reach out and schedule a delivery or arrange a pickup from a local courier facility.
  failed_delivery = 'failed_delivery',
  // Your parcel has been delivered to the destination location.
  delivered = 'delivered',
}

export enum DeliveryTypeEnum {
  for_self = 'for_self',
  for_others = 'for_others',
}

export interface IDelivery {
  id?: string
  createdAt?: string
  updatedAt?: string
  date?: string
  status?: DeliveryStatusEnum
  state?: DeliveryStateEnum
  type?: DeliveryTypeEnum
  confirmCode?: string
  customerNotes?: string
  courierNotes?: string
  routePointNumber?: string
  deliveryChainCustomerId?: string
  deliveryPlan?: IDeliveryPlan
  subscriptionId?: string
  courierId?: string
  redeliveryId?: string
  paymentId?: string
  addressId?: string
  customerId?: string
  adminId?: string
  packagingTargetId?: string
  redelivery?: IDelivery
  redeliveries?: IDelivery[]
  payment?: IPayment
  customer?: ICustomer
  address?: IAddress
  courier?: ICourier
  subscription?: ISubscription
  deliveryBoxes?: IDeliveryBox[]
  admin?: IAdmin
  walletTransactions?: IWalletTransaction[]
  week?: number
}

export class Delivery {
  public props: IDelivery = {}

  constructor(data?: IDelivery) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getWeek(): number {
    return this.props.week || 0
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getStatus(): DeliveryStatusEnum | undefined {
    return this.props?.status
  }

  getState(): DeliveryStateEnum | undefined {
    return this.props.state
  }

  getType(): DeliveryTypeEnum | undefined {
    return this.props.type
  }

  getConfirmCode(): string {
    return this.props.confirmCode || ''
  }

  getCustomerNotes(): string {
    return this.props.customerNotes || ''
  }

  getCourierNotes(): string {
    return this.props.courierNotes || ''
  }

  getSubscription(): Subscription {
    return new Subscription(this.props.subscription)
  }

  getDeliveryBoxes(): DeliveryBox[] {
    return this.props.deliveryBoxes?.map((deliveryBox) => new DeliveryBox(deliveryBox)) || []
  }

  getDeliveryPlan(): DeliveryPlan {
    return new DeliveryPlan(this.props.deliveryPlan)
  }

  getSubscriptionId(): string {
    return this.props.subscriptionId || ''
  }

  getCourier(): Courier {
    return new Courier(this.props.courier)
  }

  getCourierId(): string | undefined {
    return this.props.courierId
  }

  getRoutePointNumber(): string {
    return this.props.routePointNumber || ''
  }

  getReDeliveryId(): string {
    return this.props.redeliveryId || ''
  }

  getPaymentId(): string {
    return this.props.paymentId || ''
  }

  getAddressId(): string {
    return this.props.addressId || ''
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getPackagingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getReDelivery(): Delivery {
    return new Delivery(this.props.redelivery || {})
  }

  getReDeliveries(): Delivery[] {
    return (this.props.redeliveries || []).map((redelivery: IDelivery) => new Delivery(redelivery))
  }

  getPayment(): Payment {
    return new Payment(this.props.payment || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getAddress(): Address {
    return new Address(this.props.address || {})
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getWalletTransactions(): WalletTransaction[] {
    return (this.props.walletTransactions || []).map(
      (walletTransaction: IWalletTransaction) => new WalletTransaction(walletTransaction)
    )
  }

  /* --------------------------------- Custom --------------------------------- */

  getDisplayStatus(): string {
    return displayDeliveryStatusEnum(this.getStatus())
  }

  getDisplayState(): string {
    return displayDeliveryStateEnum(this.getState())
  }

  getHasNextDelivery(): boolean {
    return (
      this.getStatus() === DeliveryStatusEnum.processing &&
      moment(this.getDate()).toDate().getTime() > new Date().getTime()
    )
  }

  /* --------------------------------- Static --------------------------------- */

  static generateHasNextDelivery(delivery: Delivery): boolean {
    return (
      delivery.getStatus() === DeliveryStatusEnum.processing &&
      moment(delivery.getDate()).toDate().getTime() > new Date().getTime()
    )
  }
}

export const displayDeliveryStatusEnum = (deliveryStatusEnum?: DeliveryStatusEnum): string => {
  switch (deliveryStatusEnum) {
    case DeliveryStatusEnum.processing:
      return 'درحال انتظار'
    case DeliveryStatusEnum.cancel:
      return 'لغو شده'
    case DeliveryStatusEnum.customer_absence:
      return 'بازگشت خورده'
    case DeliveryStatusEnum.success:
      return 'ارسال شده'
    default:
      return ''
  }
}

export const displayDeliveryStateEnum = (deliveryStateEnum?: DeliveryStateEnum): string => {
  switch (deliveryStateEnum) {
    case DeliveryStateEnum.pending:
      return 'ارسال خواهد شد'
    case DeliveryStateEnum.rejected:
      return 'رد شده'
    case DeliveryStateEnum.ready:
      return 'آماده'
    case DeliveryStateEnum.out_for_delivery:
      return 'در حال ارسال'
    case DeliveryStateEnum.in_transit:
      return 'در حال بارگذاری'
    case DeliveryStateEnum.failed_delivery:
      return 'ارسال ناموفق'
    case DeliveryStateEnum.delivered:
      return 'تحویل‌‌ داده شده'
    default:
      return ''
  }
}

export const getTurnNumber = (turn: number): string => {
  switch (turn) {
    case 1:
      return 'اول'
    case 2:
      return 'دوم'
    case 3:
      return 'سوم'
    case 4:
      return 'چهارم'
    default:
      return ''
  }
}
export const displayShiftNameDeliveryDate = (index: string | number) => {
  switch (index) {
    case 1:
      return 'نوبت اول'
    case 2:
      return 'نوبت دوم'
    case 3:
      return 'نوبت سوم'
    case 4:
      return 'نوبت چهارم'
    case 5:
      return 'نوبت پنجم'
    case 6:
      return 'نوبت ششم'
    case 7:
      return 'نوبت هفتم'
    case 8:
      return 'نوبت هشتم'
    case 9:
      return 'نوبت نهم'
    default:
      return '-'
  }
}
