import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {CircularLoading} from 'components/circularLoading'

interface INavigationOutlet {
  loaderClassName?: string
  bodyClassName?: string
  fallbackComponent?: NonNullable<React.ReactNode> | null
}

const NavigationOutlet: React.FC<INavigationOutlet> = ({
  loaderClassName = '',
  bodyClassName = undefined,
  fallbackComponent = <CircularLoading />,
}) => {
  return (
    <Suspense fallback={<div className={loaderClassName}>{fallbackComponent}</div>}>
      <Outlet />
    </Suspense>
  )
}

export default NavigationOutlet
