import {Suspense, lazy} from 'react'
import Router from 'routers'

// providers
const LayoutProvider = lazy(() =>
  import('providers/layout').then((module) => ({
    default: module.LayoutProvider,
  }))
)
const LayoutSplashScreen = lazy(() =>
  import('providers/layout').then((module) => ({
    default: module.LayoutSplashScreen,
  }))
)
const ApiProvider = lazy(() => import('providers/api'))
const TextProvider = lazy(() => import('providers/text'))
const MessageProvider = lazy(() => import('providers/message'))
const UserProvider = lazy(() => import('providers/user'))
const ToolbarRefetchProvider = lazy(() => import('providers/toolbar-refetch'))
const ContainerProvider = lazy(() => import('providers/container'))

// components
const MasterInit = lazy(() =>
  import('components/template/layout/MasterInit').then((module) => ({
    default: module.MasterInit,
  }))
)
const CartProvider = lazy(() => import('providers/cart'))
import {Helmet} from 'react-helmet'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ContainerProvider>
        <LayoutProvider>
          <MessageProvider>
            <ApiProvider>
              <TextProvider>
                <UserProvider>
                  <CartProvider>
                    <ToolbarRefetchProvider>
                      <Helmet>
                        <title>فروشگاه آنلاین سرسبد</title>
                        <meta
                          name='description'
                          content='خرید آنلاین هفتگی و ماهانه میوه، سبزی و صیفی'
                        />
                        <meta
                          property='og:description'
                          content='خرید آنلاین هفتگی و ماهانه میوه، سبزی و صیفی'
                        />
                      </Helmet>
                      <Router />
                      <MasterInit />
                    </ToolbarRefetchProvider>
                  </CartProvider>
                </UserProvider>
              </TextProvider>
            </ApiProvider>
          </MessageProvider>
        </LayoutProvider>
      </ContainerProvider>
    </Suspense>
  )
}

export {App}
