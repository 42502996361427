import {
  TransactionCreatedByEnum,
  TransactionPaymentTypeEnum,
  TransactionStatusEnum,
  TransactionStatusPersianEnum,
  TransactionTypeEnum,
} from 'enums/transaction'
import {PaymentTXNDetails} from './Payment'
import {Customer, ICustomer} from './Customer'
import {ISubscription, Subscription} from './Subscription'
import {Photo} from './Photo'
import {IPhotoUse, PhotoUse} from './PhotoUse'
import {IWallet, Wallet} from './Wallet'

export interface ITransaction {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  amount?: number
  amountOnline?: number
  amountCard?: number
  amountWallet?: number
  createdBy?: TransactionCreatedByEnum
  type?: TransactionTypeEnum
  paymentType?: TransactionPaymentTypeEnum
  txnDetails?: PaymentTXNDetails
  status?: TransactionStatusEnum
  subscriptionId?: string
  customerId?: string
  customer?: ICustomer
  subscription?: ISubscription
  photoUses?: IPhotoUse[]
  walletId?: string
  wallet?: IWallet
  description?: string
  token?: string
  statusNumber?: number
  statusMessage?: string
  terminalNumber?: number
  orderId?: string
  rrn?: string
  cardNumber?: string
  primaryTraceNumber?: number
  secondaryTraceNumber?: number
}

export class Transaction {
  public props: ITransaction = {}

  constructor(data?: ITransaction) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt ? new Date(this.props.createdAt) : new Date()
  }

  getUpdatedAt(): Date {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : new Date()
  }

  getAmount(): number {
    return this.props.amount || 0
  }

  getAmountOnline(): number {
    return this.props.amountOnline || 0
  }

  getAmountCard(): number {
    return this.props.amountCard || 0
  }

  getAmountWallet(): number {
    return this.props.amountWallet || 0
  }

  getCreatedBy(): TransactionCreatedByEnum {
    return this.props.createdBy as TransactionCreatedByEnum
  }

  getType(): TransactionTypeEnum {
    return this.props.type as TransactionTypeEnum
  }

  getPaymentType(): TransactionPaymentTypeEnum {
    return this.props.paymentType as TransactionPaymentTypeEnum
  }

  getTnxDetails(): PaymentTXNDetails | undefined {
    return this.props.txnDetails
  }

  getStatus(): TransactionStatusEnum {
    return this.props.status as TransactionStatusEnum
  }

  getSubscriptionId(): string {
    return this.props.subscriptionId || ''
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || undefined)
  }

  getSubscription(): Subscription {
    return new Subscription(this.props.subscription || {})
  }

  getMainImage(): Photo {
    if (this.props.photoUses && this.props.photoUses.length > 0) {
      return new PhotoUse(this.props.photoUses[0]).getPhoto()
    }
    return new Photo()
  }

  getWalletId(): string {
    return this.props.walletId || ''
  }

  getWallet(): Wallet {
    return new Wallet(this.props.wallet || {})
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getToken(): string {
    return this.props.token || ''
  }

  getStatusNumber(): number {
    return this.props.statusNumber || 0
  }

  getStatusMessage(): string {
    return this.props.statusMessage || ''
  }

  getTerminalNumber(): number {
    return this.props.terminalNumber || 0
  }

  getOrderId(): string {
    return this.props.orderId || ''
  }

  getRRN(): string {
    return this.props.rrn || ''
  }

  getCardNumber(): string {
    return this.props.cardNumber || ''
  }

  getPrimaryTraceNumber(): number {
    return this.props.primaryTraceNumber || 0
  }

  getSecondaryTraceNumber(): number {
    return this.props.secondaryTraceNumber || 0
  }
  /* -------------------------------------------------------------------------- */
  /*                             Customs                                       */
  /* -------------------------------------------------------------------------- */

  getPhotoUsesLength(): number {
    return this.props.photoUses?.length || 0
  }
  getCreatedAtTimeDisplay(): string {
    const date = this.getCreatedAt()
    const hours = date.getHours() // => 9
    const minuts = date.getMinutes() // =>  30

    return `${hours}:${minuts}`
  }

  getAmountDisplay(): string {
    return this.props.amount + ' تومان'
  }

  getStatusDisplay(): string {
    switch (this.props.status) {
      case TransactionStatusEnum.pending:
        return TransactionStatusPersianEnum.pending
      case TransactionStatusEnum.paid:
        return TransactionStatusPersianEnum.paid
      case TransactionStatusEnum.expired:
        return TransactionStatusPersianEnum.expired
      case TransactionStatusEnum.failed:
        return TransactionStatusPersianEnum.failed
      case TransactionStatusEnum.refunded:
        return TransactionStatusPersianEnum.refunded
      case TransactionStatusEnum.waiting_to_accept:
        return TransactionStatusPersianEnum.waiting_to_accept
      case TransactionStatusEnum.failed_to_refund:
        return TransactionStatusPersianEnum.failed_to_refund
      default:
        return ''
    }
  }
}

export const displayTransactionType = (transactionType: TransactionTypeEnum): string => {
  switch (transactionType) {
    case TransactionTypeEnum.change_to_cheaper_box:
      return 'تغییر به جعبه ارزانتر'
    case TransactionTypeEnum.change_to_more_expensive_box:
      return 'تغییر به جعبه گرانتر'
    case TransactionTypeEnum.buy_subscription:
      return 'ثبت سفارش'
    case TransactionTypeEnum.refunded:
      return 'کارت به کارت'
    case TransactionTypeEnum.wallet:
      return 'اعتبار'
    case TransactionTypeEnum.cancel_subscription:
      return 'لغو سفارش'
    case TransactionTypeEnum.cancel_delivery:
      return 'لغو ارسال'
    case TransactionTypeEnum.wallet_deposit:
      return 'سپرده'
    case TransactionTypeEnum.wallet_withdrawal:
      return 'برداشت از اعتبار'
    case TransactionTypeEnum.wallet_admin_gift:
      return 'افزایش اعتبار'
    case TransactionTypeEnum.wallet_coupon_activation:
      return 'فعال سازی اعتبار'
    default:
      return '-'
  }
}

export const displayTransactionPaymentType = (
  transactionPaymentType: TransactionPaymentTypeEnum
): string => {
  switch (transactionPaymentType) {
    case TransactionPaymentTypeEnum.online:
      return 'آنلاین'
    case TransactionPaymentTypeEnum.credit:
      return 'کارت به کارت'
    case TransactionPaymentTypeEnum.wallet:
      return 'اعتبار'
    default:
      return '-'
  }
}

export const displayTransactionStatus = (transactionPaymentType: TransactionStatusEnum): string => {
  switch (transactionPaymentType) {
    case TransactionStatusEnum.paid:
      return TransactionStatusPersianEnum.paid
    case TransactionStatusEnum.failed:
      return TransactionStatusPersianEnum.failed
    case TransactionStatusEnum.pending:
      return TransactionStatusPersianEnum.pending
    case TransactionStatusEnum.expired:
      return TransactionStatusPersianEnum.expired
    case TransactionStatusEnum.refunded:
      return TransactionStatusPersianEnum.refunded
    case TransactionStatusEnum.waiting_to_accept:
      return TransactionStatusPersianEnum.waiting_to_accept
    default:
      return '-'
  }
}
