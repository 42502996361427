import {Box, IBox} from './Box'
import {IPackagingContentTarget, PackagingContentTarget} from './PackagingContentTarget'
import {IPackagingTarget, PackagingTarget} from './PackagingTarget'

export interface IDeliveryBox {
  id?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  quantity?: number
  price?: number
  deliveryId?: string
  boxId?: string
  packagingTargetId?: string
  packagingTarget?: IPackagingTarget
  box?: IBox
  deliveryBoxProducts?: IPackagingContentTarget[]
  totalPrice?: number
}

export class DeliveryBox {
  public props: IDeliveryBox = {}

  constructor(data?: IDeliveryBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDeletedAt(): Date | undefined {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : undefined
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getPrice(): number {
    return this.props.price || 0
  }

  getBoxId(): string | undefined {
    return this.props.boxId
  }

  getDeliveryId(): string | undefined {
    return this.props.deliveryId
  }

  getPackagingTargetId(): string | undefined {
    return this.props.packagingTargetId
  }

  getPackagingTarget(): PackagingTarget {
    return new PackagingTarget(this.props.packagingTarget)
  }

  getBox(): Box {
    return new Box(this.props.box)
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }

  getDeliveryBoxProducts(): PackagingContentTarget[] {
    return (this.props.deliveryBoxProducts || []).map(
      (deliveryBoxProduct: IPackagingContentTarget) =>
        new PackagingContentTarget(deliveryBoxProduct)
    )
  }
}
