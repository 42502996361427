import NavigationOutlet from 'components/navigationOutlet'
import {FC, useEffect} from 'react'
import {useLocation, useSearchParams} from 'react-router-dom'

const ProtectedLayout: FC = () => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('isModalOpened')) {
      searchParams.delete('isModalOpened')
      setSearchParams(searchParams)
    }
  }, [location.pathname])

  return <NavigationOutlet />
}

export default ProtectedLayout
