import {useStyles} from './style'
import {Typography} from 'components/elements/Typography'

interface ICircularLoading {
  isHiddenTitle?: boolean
  className?: string
}

export const CircularLoading: React.FC<ICircularLoading> = ({
  isHiddenTitle = false,
  className = '',
}) => {
  const {classes} = useStyles()
  return (
    <div className={[classes.root, className].join(' ')}>
      {!isHiddenTitle && (
        <Typography type='p' color='#005927'>
          سرسبد
        </Typography>
      )}
      <Typography type='p' className={classes.loadingLetter}>
        .
      </Typography>
      <Typography type='p' className={classes.loadingLetter}>
        .
      </Typography>
      <Typography type='p' className={classes.loadingLetter}>
        .
      </Typography>
    </div>
  )
}
