import {Address, Customer, User} from 'models'
import {createContext} from 'react'

export interface IUserContext {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  isLogin: boolean
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  getUserInfo: () => Promise<void>
  logout: () => void
  selectedAddress: Address | null
  setSelectedAddress: React.Dispatch<React.SetStateAction<Address | null>>
  allAddresses: Address[]
}

export default createContext<IUserContext>({
  user: new User(),
  setUser: () => {},
  customer: new Customer(),
  setCustomer: () => {},
  token: '',
  setToken: () => {},
  isLogin: false,
  setIsLogin: () => {},
  isLoading: false,
  getUserInfo: () => new Promise(() => {}),
  logout: () => {},
  selectedAddress: null,
  setSelectedAddress: () => {},
  allAddresses: [],
})
