import {Box, IBox} from './Box'
import {IPackagingTarget, PackagingTarget} from './PackagingTarget'

export interface ICustomerCartItem {
  id?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  quantity?: number
  boxId?: string
  customerCartId?: string
  box?: IBox
  packagingTarget?: IPackagingTarget
}

export class CustomerCartItem {
  public props: ICustomerCartItem = {}

  constructor(data?: ICustomerCartItem) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getCustomerCartId(): string {
    return this.props.customerCartId || ''
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getPackagingTarget(): PackagingTarget {
    return new PackagingTarget(this.props.packagingTarget || {})
  }
}
